export enum Page {
  AccountMap = 'AccountMap',
  Admin = 'Admin',
  FinalApprover = 'FinalApprover',
  Editor = 'Editor',
  BookingDetails = 'BookingDetails',
  CalculationDetails = 'CalculationDetails',
  DownloadDataFile = 'DownloadDataFile',
  CreateBookings = 'CreateBookings',
  CreateEntries = 'CreateEntries',
  CreateRule = 'CreateRule',
  CreateRuleV2 = 'CreateRuleV2',
  Exports = 'Exports',
  GeneralLedger = 'GeneralLedger',
  Landing = 'Landing',
  LocationMap = 'LocationMap',
  ManageBookings = 'ManageBookings',
  ManageRules = 'ManageRules',
  PostToAce = 'PostToAce',
  RuleDetails = 'RuleDetails',
  Settings = 'Settings',
  Submissions = 'Submissions',
  View = 'View',
  UpdateRule = 'UpdateRule',
  UpdateBooking = 'UpdateBooking',
}
